import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../AuthContext";

export default function FinishSend() {
  const navigate = useNavigate();
  const { docid } = useParams();
  const [doc, setDoc] = useState();
  const [err, setErr] = useState();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const doc = (await axios.get(`${process.env.REACT_APP_API_URL}/docs/${docid}`)).data;
        setDoc(doc);
      } catch (err) {
        console.error(err);
        setErr(err.response?.data);
      }
    })(); 
  }, []);

  function message(){
    if(searchParams.get("type") == "signed"){
      let link = `/home/complete/${docid}`
      return <p>Thank you for signing <b>{doc?.name}</b> <br/> The document is complete and can be viewed <Link to={link}>here</Link>.</p>
    } else {
      let link = `/home/outbox/${docid}`
      return <p>Well done! The document <b>{doc?.name}</b> has been sent! Click <Link to={link}>here</Link> to view it </p>
    }
  }

  function header(){
    if(searchParams.get("type") == "signed"){
      return 'Document Signed'
    } else {
      return 'Document sent'
    }
  }

  return (
    <Modal show={true} onHide={() => navigate("/home")} backdrop="static">
      <Modal.Header className="bg-light">
        <Modal.Title>{header()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message()}
        {err?.message && <p className="text-danger">{err?.message}</p>}
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button as={Link} to="/home" variant="light" className="me-auto">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
