import axios from "axios";
import { useEffect, useState } from "react";
import { createSearchParams, Link, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import InputGroup from "react-bootstrap/InputGroup";
import Stack from "react-bootstrap/Stack";
import logo from "../data/logo_main.png";
import logoIcon from "../data/logo_icon.png";
import createDocument from "../data/create_document.png";
import createTemplate from "../data/create_template.png";
import useTemplate from "../data/use_template.png";
import signSend from "../data/sign_and_send.png";
import { useAuth } from "../AuthContext";
import useWindowDimensions from "../utils";

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useAuth();
  const [docs, setDocs] = useState([]);
  const [numCompleteOutbox, setNumCompleteOutbox] = useState(0);
  const [numAwaitingSigniture, setNumAwaitingSigniture] = useState(0);
  const [numInbox, setNumInbox] = useState(0);
  const [numOutbox, setNumOutbox] = useState(0);
  const [numRejected, setNumRejected] = useState(0);
  const [err, setErr] = useState();
  const { width } = useWindowDimensions();
  
  const [showContainer, setShowContainer] = useState(true); // State variable to control visibility 

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(width < 1280);
  const [isDesktopMenuVisible, setIsDesktopMenuVisible] = useState(width > 991);
  const [navbarWidth, setNavbarWidth] = useState(322);

  useEffect(() => {
    (async () => {
      try {
        let docs = (await axios.get(`${process.env.REACT_APP_API_URL}/docs`)).data;
        setDocs(docs);
      } catch (err) {
        console.log(err.response);
        setErr(err.response?.data);
      }
    })();
  }, []);

  useEffect(() => {
    setNumInbox(docs.filter(doc => doc.ownerid !== user.userid && [2, 3].includes(doc.state)
      && doc.signers.find(signer => signer.userid === user.userid)?.state === 1).length);
    setNumOutbox(docs.filter(doc => doc.state === 2
      && doc.signers.find(signer => signer.userid === user.userid)?.state === 1).length);
    setNumCompleteOutbox(docs.filter(doc => doc.state === 3
      && doc.signers.find(signer => signer.userid === user.userid)?.state === 2).length);
    setNumAwaitingSigniture(docs.filter(doc => doc.state === 2
      && doc.signers.find(signer => signer.userid === user.userid)?.state === 2).length);
    setNumRejected(docs.filter(doc => doc.ownerid === user.userid && doc.state === 0).length);  
  }, [docs]);


  useEffect(() => {
    (async () => {
      try {
        let docs = (await axios.get(`${process.env.REACT_APP_API_URL}/docs`)).data;
        setDocs(docs);
      } catch (err) {
        console.log(err.response);
        setErr(err.response?.data);
      }
    })();

    // Check if the current location includes "/home"
    if (location.pathname == "/home") {
      setShowContainer(true); // Show the container if the path includes "/home"
    } else {
      setShowContainer(false); // Hide the container for other routes
    }
  }, [location]);

  function toggleMenu() {
    setIsMobileMenuVisible(!isMobileMenuVisible);
    setIsDesktopMenuVisible(isMobileMenuVisible);
  }

  useEffect(() => {
    if(width < 992 ){
      setIsMobileMenuVisible(true);
      setIsDesktopMenuVisible(false);
    } 
    if(width > 991 ){
      setIsMobileMenuVisible(false);
      setIsDesktopMenuVisible(true);
    } 
    if(width < 1280 ){
      setNavbarWidth(45);
    }else {
      setNavbarWidth(322);
    }
  }, [width]);

  async function logout(event) {
    event.preventDefault();
    try {
      setUser(null);
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`);
      navigate("/auth/login");
    } catch (err) {
      console.error(err.response?.data);
    }
  }

  function search(event) {
    let query = event.target.value;
    if (query.length) {
      if (!location?.pathname.includes("search")) navigate({
        pathname: "search",
        search: createSearchParams({ name: query }).toString()
      });
      else setSearchParams({ name: query });
    } else {
      setSearchParams({});
      navigate(".");
    }
  }

  return (
    <Stack direction="horizontal" className="vh-100">
    {/* This is the desktop view menu (sideNav)*/}
      <Navbar collapseOnSelect id="navBar" as={Stack} gap={0}  className={isDesktopMenuVisible ? 'h-100 bg-black text-main vs-navbar overflow' : 'd-none'}>
        <div className={width < 1280 ? 'w-100 navbar-list-item d-flex justify-content-end' : 'd-none'}>
          <Button variant="black" onClick={ toggleMenu } className="navbar-text">
            <span className="material-icons-outlined sidebar-icon">menu</span>
          </Button>
        </div>
        <Navbar.Brand as={Link} to="/home">
        <Image src={logo} fluid className="p-4" />
        </Navbar.Brand>
          <Stack gap={0} className="align-items-start py-2">
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="/home" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">home</span>
                <span className="navbar-text-span">HOME</span>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="inbox" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">mark_email_read</span>
                <span className="navbar-text-span">AWAITING MY SIGNATURE</span>
                <div variant="dark" className="ml-auto pe-2 ps-2 as-c bg-dark text-light">
                  {numOutbox}
                </div>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="awaiting-signiture" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">send</span>
                <span className="navbar-text-span" >AWAITING OTHER SIGNATURES</span>
                <div variant="dark" className="ml-auto pe-2 ps-2 as-c bg-dark text-light">
                  {numAwaitingSigniture}
                </div>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="complete" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">send</span>
                <span className="navbar-text-span">COMPLETED</span>
                <div variant="dark" className="ml-auto pe-2 ps-2 as-c bg-dark text-light">
                  {numCompleteOutbox}
                </div>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="rejected" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">file_copy</span> 
                <span className="navbar-text-span">REJECTED</span>
                <div variant="dark" className="ml-auto pe-2 ps-2 as-c bg-dark text-light">
                  {numRejected}
                </div>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="drafts" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">edit_note</span>
                <span className="navbar-text-span">DRAFTS</span>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="templates" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">file_copy</span>
                <span className="navbar-text-span">TEMPLATES</span>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="archives" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">inventory_2</span>
                <span className="navbar-text-span">ARCHIVES</span>
              </Button>
            </div>
          </Stack>
          <Stack gap={0} className="align-items-start py-2">
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="new/validate" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">security</span>
                <span className="navbar-text-span">VALIDATE</span>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="account/profile" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">person</span>
                <span className="navbar-text-span">PROFILE</span>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button as={Link} to="account/settings" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">settings</span>
                <span className="navbar-text-span">SETTINGS</span>
              </Button>
            </div>
            <div className="w-100 navbar-list-item">
              <Button variant="black-alt" onClick={logout} className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">logout</span>
                <span className="navbar-text-span">LOG OUT</span>
              </Button>
            </div>
          </Stack>
        </Navbar>
        {/* This is the mobile view menu (sideNav)*/}
        <Navbar collapseOnSelect id="navBar" as={Stack} gap={0}  className={isMobileMenuVisible ? 'h-100 bg-black text-main vs-mobile-navbar overflow' : 'd-none' }>
          <div className="w-10 navbar-list-item">
            <Button variant="dark-blue" onClick={ toggleMenu } className={!isDesktopMenuVisible ? 'navbar-text' : 'd-none'}>
              <span className="material-icons-outlined sidebar-icon">menu</span>
            </Button>
          </div>
          <Navbar.Brand as={Link} to="/home" className="d-none d-lg-block">
            <Image src={logo} fluid className="p-4" />
          </Navbar.Brand>
          <Stack gap={0} className="align-items-start py-2">
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="/home" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">home</span>
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="inbox" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">mark_email_read</span>
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="awaiting-signiture" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">send</span>
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="complete" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">send</span>
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="rejected" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">file_copy</span> 
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="drafts" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">edit_note</span>
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="templates" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">file_copy</span>
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="archives" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">inventory_2</span>
              </Button>
            </div>
          </Stack>
          <Stack gap={0} className="align-items-start py-2">
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="new/validate" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">security</span>
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="account/profile" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">person</span>
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button as={Link} to="account/settings" variant="black" className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">settings</span>
              </Button>
            </div>
            <div className="w-10 navbar-list-item">
              <Button variant="black-alt" onClick={logout} className="navbar-text">
                <span className="material-icons-outlined sidebar-icon">logout</span>
              </Button>
            </div>
          </Stack>
        </Navbar>
      {/* Navbar on the top: */}
      <Stack>
        <Navbar style={{ width: isMobileMenuVisible ? `${width - navbarWidth}px` : '' }} className="bg-main text-white ps-5 pe-2">
          <h3 className="me-auto welcome-text">WELCOME, <i className="name-text">{user.firstname} {user.lastname}</i></h3>
          <div className="mx-2 d-none d-lg-block">
            <InputGroup style={{ width: "230px", minWidth: "230px" }}>
              <Form.Control size="sm" className="search-box" value={searchParams.get("name") ?? ""} onChange={search}/>
              <InputGroup.Text className="search-box"><span className="material-icons-outlined">search</span></InputGroup.Text>
            </InputGroup>
          </div>
          <Button variant="light btn-padding mr-2">
            <span className="material-icons-outlined">live_help</span>
          </Button>
        </Navbar>
        <Stack direction="horizontal" className={width < 1280 ? "h-100 align-items-stretch overflow-y-scroll overflow-x-hidden mx-2 d-flex flex-column mt-10 home-mobile-view" : "h-100 align-items-stretch mx-2 overflow"}>
          <Outlet context={{ docs, setDocs, err, setErr }} />
          {showContainer &&
            <div className="container container-margin">
              <div className="row">
                <div className="col-lg-9 col-md-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 home-card-container">
                      <Link to="new/create" style={{ textDecoration: 'none' }}>
                        <div className="sub-container">
                          <div className="row h-100 w-100">
                            <div className="row bg-black">
                              <img src={createDocument} className="card-image my-4"></img>
                            </div>
                            <div className="row text-white bg-black">
                              <div className="home-card-headings hr-lines">
                                <h2 className="">CREATE</h2>
                                <h2 className="headings-accent">DOCUMENT</h2>
                              </div>
                            </div>
                            <div className="row bg-black text-white ">
                              <div className="home-card-description py-3">
                                Create, edit and invite multiple users to sign a document seamlessly.
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-md-12 home-card-container">
                      <Link to="new/create?signAndSend=true" style={{ textDecoration: 'none' }}>
                        <div className="row h-100 w-100">
                          <div className="row bg-black">
                            <img src={signSend} className="card-image my-4"></img>
                          </div>
                          <div className="row text-white bg-black">
                            <div className="home-card-headings hr-lines">
                              <h2 className="">SIGN & SEND</h2>
                              <h2 className="headings-accent">DOCUMENT</h2>
                            </div>
                          </div>
                          <div className="row bg-black text-white ">
                            <div className="home-card-description py-3">
                              Quickly sign and send a document.
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-lg-6 col-md-12 home-card-container">
                      <Link to="new/create?template=true&state=1" style={{ textDecoration: 'none' }}>
                        <div className="row w-100 h-100">
                          <div className="row bg-black">
                            <img src={createTemplate} className="card-image my-4"></img>
                          </div>
                          <div className="row text-white bg-black">
                            <div className="home-card-headings hr-lines">
                              <h2 className="">CREATE</h2>
                              <h2 className="headings-accent">TEMPLATE</h2>
                            </div>
                          </div>
                          <div className="row bg-black text-white ">
                            <div className="home-card-description py-3">
                              Create reusable documents for endless usability.
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-md-12 home-card-container">
                      <Link to="new/template" style={{ textDecoration: 'none' }}>
                        <div className="row w-100 h-100">
                          <div className="row bg-black">
                            <img src={useTemplate} className="card-image my-4"></img>
                          </div>
                          <div className="row text-white bg-black">
                            <div className="home-card-headings hr-lines">
                              <h2 className="">USE</h2>
                              <h2 className="headings-accent">TEMPLATE</h2>
                            </div>
                          </div>
                          <div className="row bg-black text-white ">
                            <div className="home-card-description py-3">
                              Quickly send an existing template for quick and convenient signing.
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                </div>
                <div className="row pt-2" style={{paddingBottom: '40px'}}>
                  <div className="col-lg-6 col-md-12 home-card-container">
                    <div className="row w-100 h-100">
                      <div className="row text-white bg-black body-input">
                        <div className="row text-white bg-black mt-3 mb-3 border-none text-break">
                          <h1 className="card-header-text my-2 pl-0 pr-0">
                            <span className="ml-1"><span className="title-first-word">SIGNED</span>&nbsp;DOCUMENTS RECEIVED</span>
                          </h1>
                        </div>
                      </div>
                      <div className="row bg-white">
                        <div className="home-card-description py-4">
                          <span className="material-icons-outlined widget-icon widget-overlap">mark_email_read</span>
                          <span className="widget-number widget-overlap">{numInbox.toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 home-card-container">
                    <div className="row w-100 h-100">
                      <div className="row text-white bg-black body-input">
                        <div className="row text-white bg-black mt-3 mb-3 border-none text-break">
                          <h1 className="card-header-text my-2 pl-0 pr-0">
                             <span className="ml-1"><span className="title-first-word">DOCUMENTS</span>&nbsp; SENT</span>
                          </h1>
                        </div>
                      </div>
                      <div className="row bg-white">
                        <div className="home-card-description py-4">
                          <span className="material-icons-outlined widget-icon widget-overlap">send</span>
                          <span className="widget-number widget-overlap">{numOutbox.toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 d-none d-lg-block">
                <div className="logo-icon-container">
                  <img src={logoIcon} className="logo-icon"></img>
                </div>
              </div>
            </div>
            {/* <div className="row pt-2">
              <div className="col-9">
                <div className="row">
                  <div className="col-6 home-card-container">
                    <div className="row w-100 h-100">
                      <div className="row text-white bg-black body-input">
                        <div className="row text-white bg-black mt-3 mb-3 border-none">
                          <h1 className="card-header-text my-2 pl-0 pr-0">
                            <span>RECENT</span> <span className="title-first-word ml-1">DOCUMENTS</span>
                          </h1>
                        </div>
                      </div>
                      <div className="row bg-white">
                        <div className="home-card-description py-4">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 home-card-container">
                    <div className="row w-100 h-100">
                      <div className="row text-white bg-black body-input">
                        <div className="row text-white bg-black mt-3 mb-3 border-none">
                          <h1 className="card-header-text my-2 pl-0 pr-0">
                            <span className="title-first-word">DRAFT</span> <span className="ml-1">DOCUMENTS</span>
                          </h1>
                        </div>
                      </div>
                      <div className="row bg-white">
                        <div className="home-card-description py-4">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        }
        </Stack>
      </Stack>
    </Stack>
  );
}
